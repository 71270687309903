import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO
      title="NP Global Consulting Co., Ltd."
      description="株式会社 NPグローバルコンサルティング コーポレートサイト"
    />
    <div class="text-center">
      <StaticImage
        src="../images/NPGC-logo-bk.png"
        width={100}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="A Gatsby astronaut"
        style={{ marginBottom: `1.45rem` }}
      />
    </div>
    <div class="home-container">
      <h1 class="home-text">
        Our Mission
      </h1>
      <p class="home-text">
        異なる背景を有する人々が平和的に共存できる社会を創ります
      </p>
      <h1 class="home-text">
        Our Vision
      </h1>
      <p class="home-text">
        国と国をつなげて、新たな価値を具体化します<br />
        人と人をつなげて、新たな知を生み出します
      </p>
      <h1 class="home-text">
        Our Business
      </h1>
      <p class="home-text">
        国内外の課題を解決するためのコンサルティングサービス<br />
        人材育成プログラムの企画・実施・評価サービス<br />
        若年層のキャリア形成支援サービス
      </p>
    </div>
  </Layout>
)

export default IndexPage
